import ReactDOM from "react-dom/client";

/*
    Reason for this: I was getting hydration errors
    (eg `Hydration failed because the initial UI does not match what was rendered on the server.`)
    several resources on the web suggested this here "replace" function:
*/
export const replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}
